<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Shipping carriers integrations')"
          text-class="text-h6"
        />

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="toggleFilters"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Add integration')"
          size="sm"
          no-caps
          unelevated
          @click="create(this.deliveryServiceId)"
        />
      </q-card-section>

      <filter-collapse
        :is-open="isOpen"
        :options="{
          defaultFilter: serverParams.filter,
          fields: activatedFields,
          values: {
            states: statuses
          },
          style: {
            noGroups: true
          }
        }"
        @submit="handleFiltersSubmit"
        @close="toggleFilters"
      />

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
          style="height: calc(100vh - 130px);"
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="integrations"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="integrationsLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowClick(props.row)"
            >
              <q-td
                key="deliveryServiceLogo"
                :props="props"
                style="max-width: 100px; height: 50px;"
              >
                <img
                  v-if="props.row._embedded.deliveryService.logo"
                  :src="getLogoUrl(props.row._embedded.deliveryService.logo)"
                  style="width: 100px; height: 50px; object-fit: contain;"
                >
                <strong v-else class="text-h5">{{ props.row._embedded.deliveryService.name }}</strong>
              </q-td>

              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="name"
                :props="props"
              >
                <strong>{{ props.row.name || '--' }}</strong>
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                key="state"
                :props="props"
              >
                <q-toggle
                  color="light-blue-9"
                  :model-value="props.row.state === 'active'"
                  @update:model-value="handleStateChange(props.row)"
                />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'
import FilterCollapse from '../../components/filters/FilterCollapse.vue'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Components
import Search from '../../components/search/Search.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Helpers
import { getLogoUrl } from "./../../helpers/helpers"

// Store
import { store } from '../../vueX/store'

export default {
  name: 'DMIntegrations',
  components: {
    Search,
    FilterCollapse
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Handler'),
          name: 'deliveryServiceLogo',
          align: 'center'
        },
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        }
      ],
      statuses: [
        { id: 'active', title: this.$t('Active') },
        { id: 'inactive', title: this.$t('Inactive') }
      ],
      activatedFields: [
        'id',
        'name',
        'deliveryService',
        'sender',
        'created.from',
        'created.to',
        'state'
      ],
      deliveryServiceId: null
    }
  },
  computed: {
    ...mapGetters([
      'integrations',
      'totalIntegrationsNumber',
      'integrationsLoading',
      'appOptions'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? this.$t('Filtered: ') + this.totalIntegrationsNumber
        : this.$t('Filter')
    }
  },
  mounted () {
    this.deliveryServiceId = this.serverParams?.filter[1]?.value ?? null

    this.loadDefaultItems()
  },
  methods: {
      getLogoUrl,
    ...mapActions([
      'loadIntegrations'
    ]),
    ...mapMutations([
      'setIntegration',
      'setIntegrations'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    toggleFilters () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({})
        .then(({ totalItems }) => {
          if (totalItems <= 0) {
            this.create(this.deliveryServiceId)
          }
        })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadIntegrations(this.serverParams)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          return { items, totalPages, page, totalItems }
        })
    },
    handleSearch (search) {
      if (search) {
        this.updateParams({
          filter: [
            {
              field: 'name',
              type: 'ilike',
              value: '%' + search + '%'
            },
            {
              field: 'comment',
              type: 'ilike',
              value: '%' + search + '%'
            },
            {
              type: 'innerjoin',
              field: 'deliveryService',
              parentAlias: 'di',
              alias: 'ds'
            },
            {
              type: 'innerjoin',
              field: 'sender',
              parentAlias: 'di',
              alias: 's'
            },
            {
              type: 'orx',
              where: 'or',
              conditions: [
                {
                  field: 'id',
                  alias: 'ds',
                  type: 'eq',
                  value: search
                },
                {
                  field: 'name',
                  alias: 'ds',
                  type: 'ilike',
                  value: '%' + search + '%'
                },
                {
                  field: 'id',
                  alias: 's',
                  type: 'eq',
                  value: search
                },
                {
                  field: 'name',
                  alias: 's',
                  type: 'ilike',
                  value: '%' + search + '%'
                }
              ]
            }
          ]
        })
      } else {
        return this.loadDefaultItems()
      }

      return this.onRequest()
    },
    handleStateChange (item) {
      const invertedState = {
        active: 'inactive',
        inactive: 'active'
      }

      const state = invertedState[item.state]

      return this.$service.deliveryServiceIntegration.save({ state }, item.id)
        .then(integration => {
          const items = this.integrations.map(item => {
            return integration.id === item.id
              ? integration
              : item
          })

          this.setIntegrations(items)
        })
    },
    create (dsId) {
      store.commit('setSelectedCourier', dsId)

      this.$router.push('/apps/shipping/entity')
    },
    onRowClick (row) {
      this.create(row._embedded?.deliveryService?.id ?? null)

      this.setIntegration(row)
    }
  }
}
</script>
